
<div class="home-slider owl-theme owl-carousel">
    <div class="slider-item slider-item-img">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="common-btn">
                            <a routerLink="/nosotros">Conocenos</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item slider-item-img-h2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="common-btn">
                            <a routerLink="/appointment">Agenda tu Cita</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item slider-item-img-h3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="common-btn">
                            <a routerLink="/appointment">Agenda tu Cita</a>
                            <a routerLink="/departments">Especialidades</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!--PRINCIPALES SERVICIOS-->

<section class="services-area pb-20 owl-theme">
        <div class="section-title pt-50 pb-10" >
            <h1>Principales Servicios</h1>
        </div>

        <div class="container pl-50">

            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide>
                        <div class="container pt-10">
                            <div class="about-area ">
                                <div class="row " >
                                    <div >
                                        <div class="service-item">
                                            <div class="service-front">
                                                <!--i class="icofont-doctor-alt"></i-->
                                                <a routerLink="/appointment">
                                                    <img class="icon" src="assets/img/iconos/services/SVG/medicinageneral.svg" >
                                                </a>
                                                <h3>Consulta Médica</h3>
                                                <p> </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                </ng-template>

                <ng-template carouselSlide>
                        <div class="container pt-10">
                            <div class="about-area">
                                <div class="row">
                                    <div>
                                        <div class="service-item">
                                            <div class="service-front">
                                                <a routerLink="/laboratorio">
                                                    <img class="icon" src="assets/img/iconos/services/SVG/laboratorio.svg"  >
                                                </a>
                                                <h3>Laboratorio</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                </ng-template>

                <ng-template carouselSlide>
                        <div class="container pt-10">
                            <div class="about-area ">
                                <div class="row">
                                    <div>
                                        <div class="service-item">
                                            <div class="service-front">
                                                <a routerLink="/ecografia">
                                                    <img class="icon" src="assets/img/iconos/services/SVG/ecografia.svg">
                                                </a>
                                                <h3>Ecografía</h3>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                <ng-template carouselSlide>
                        <div class="container pt-10">
                            <div class="about-area">
                                <div class="row">
                                    <div>
                                        <div class="service-item">
                                            <div class="service-front">
                                                <a routerLink="/imagenes">
                                                    <img class="icon" src="assets/img/iconos/services/SVG/imagenes.svg">
                                                </a>
                                                <h3>Imagenes</h3>
                                                <p> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="container pt-10">
                        <div class="about-area ">
                            <div class="row">
                                <div>
                                    <div class="service-item">
                                        <div class="service-front">
                                            <a routerLink="/ocupacional">
                                                <img class="icon" src="assets/img/iconos/services/SVG/medicinaocupacional.svg">
                                            </a>
                                            <h3>Salud Ocupacional</h3>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>


                <ng-template carouselSlide>
                    <div class="container pt-10">
                        <div class="about-area ">
                            <div class="row">
                                <div>
                                    <div class="service-item">
                                        <div class="service-front">
                                            <img class="icon" src="assets/img/iconos/services/SVG/promociones.svg">
                                            <h3>Promociones</h3>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>


            </owl-carousel-o>
    </div>
</section>



<div class="init-area pb-10">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="init-item">
                    <!--div class="about-left"--> <!--comentado en nueva version!-->
                        <img src="assets/img/home-one/home-bg4.png" alt="About">
                    <!--/div-->
                </div>
            </div>

            <div class="col-lg-6">
                <div class="init-item about-right">
                    <img src="assets/img/home-one/home-bg5.png" alt="About">
                    <h2>En Nuestro Centro de Especialidades</h2>
                        <h1>¡Somos la mejor opción para ver por tu salud!</h1>
                        <p>En centro de especialidades Medicas CEMAS S.A.,
                            vamos a encontrar una solución para mantener tu
                            bienestar gracias a nuestros médicos especialistas,
                            por eso estaras en las mejores manos.
                        </p>


                </div>
            </div>
        </div>
    </div>
</div>




<!-- Swiper -->

<swiper-container
      class="mySwiper"
      pagination="true"
      pagination-clickable="true"
      slides-per-view="4"
      space-between="30"
      centered-slides="true"

    >

<swiper-slide>
    <div class="card bg-base-100 w-96 shadow-xl">
        <div class="card-body">
          <h2 class="card-title">Card title!</h2>
          <p>If a dog chews shoes whose shoes does he choose?</p>
          <div class="card-actions justify-end">
            <button class="btn btn-primary">Buy Now</button>
          </div>
        </div>
      </div>

</swiper-slide>
<swiper-slide><div class="card bg-base-100 w-96 shadow-xl">
    <div class="card-body">
      <h2 class="card-title">Card title!</h2>
      <p>If a dog chews shoes whose shoes does he choose?</p>
      <div class="card-actions justify-end">
        <button class="btn btn-primary">Buy Now</button>
      </div>
    </div>
  </div></swiper-slide>
<swiper-slide><div class="card bg-base-100 w-96 shadow-xl">
    <div class="card-body">
      <h2 class="card-title">Card title!</h2>
      <p>If a dog chews shoes whose shoes does he choose?</p>
      <div class="card-actions justify-end">
        <button class="btn btn-primary">Buy Now</button>
      </div>
    </div>
  </div></swiper-slide>
</swiper-container>

<section class="contact-area ptb-50">
    <div class="container">
        <div class="section-title">
            <h2>Ubicanos</h2>
        </div>
    </div>
    <div class="contact-map-area">
        <div id="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1993.4039735915178!2d-80.860625061547!3d-2.2258635994386107!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x902e0983bb61851f%3A0x292983c7854f577c!2sCEMAS%20-%20Centro%20de%20especialidades%20m%C3%A9dicas!5e0!3m2!1ses!2sec!4v1686887702821!5m2!1ses!2sec" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
</section>







