<section class="error-area">
    <div class="error-item">
        <div class="d-table">
            <div class="d-table-cell ">
                <div class="error-text">
                    <div class="error-left">
                        <img  src="assets/img/error.jpg" alt="Error">
                    </div>

                    <div class="error-right">
                        <h1>404!</h1>
                        <p>Página no encontrada</p>
                        <div class="error-page-adicion" >

                            <div class="container">
                                <a class="enlace" routerLink="/services">
                                    <u>Servicios</u></a>
                            </div>

                            <div class="container">
                                <a class="enlace" routerLink="/departments">
                                    <u>Especialidades</u></a>
                            </div>
                        </div>

                        <a routerLink="/">Volver a Inicio</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
