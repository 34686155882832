<div class="page-title-area page-title-services">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Medicina Ocupacional</h2>
                <ul>
                    <li><a routerLink="/">Inicio</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Servicios</li>
                </ul>
            </div>
        </div>
    </div>
</div>




<section class="appointment-area-two">
    <div class="container">
        <div class="row appointment-wrap-two">
            <div class="col-lg-7">
                <div class="appointment-item appointment-item-two">
                    <h2>Haz convenio con nosotros</h2>
                    <span></span>

                    <div class="appointment-form">

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icon fa-solid fa-building"></i>
                                        <input type="text" name="name" class="form-control" placeholder="Nombre de Empresa">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icon fa-solid fa-address-card"></i>
                                        <input type="text" name="ruc"  class="form-control" placeholder="RUC">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icon fa-solid fa-user"></i>
                                        <input type="text" name="contact"  class="form-control" placeholder="Persona de contacto">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icon fa-solid fa-envelope"></i>
                                        <input type="text" name="email" class="form-control" placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icon fa-solid fa-phone"></i>
                                        <input type="text" name="phone_number" class="form-control" placeholder="Teléfono">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icon fa-solid fa-handshake"></i>
                                        <select class="form-control" name="type">
                                            <option>Tipo de Convenio</option>
                                            <option>Preocupacional</option>
                                            <option>Ocupacional</option>
                                            <option>Post-ocupacional</option>
                                            <option>Otros</option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                            <!--div class="text-center" method="post">
                                <button type="submit" class="btn appointment-btn">Submit</button>
                            </div-->

                            <!--?php isset($_POST["nombre"]) ? print $_POST["nombre"] : ""; ?><br-->

                    </div>
                </div>
            </div>

            <div class="col-lg-5 ">
                <div class="appointment-item-two-right">
                    <div class="appointment-item-content">

                        <h2></h2>
                        <div class="appointment-form">
                        <form id="form-citas" (submit)="sendEmail($event)" >
                            <div class="form-group">
                                <textarea name="message" class="form-control" cols="50" rows="6" placeholder="Detalles Adicionales"></textarea>
                            </div>
                            <div class="col-lg-6">
                                <div class="text-center">
                                    <!--button type="submit" class="btn appointment-btn">Agendar</button-->
                                    <input type="submit" id="button" class="appointment-btn btn-success" value="Enviar" >
                                </div>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container">
    <div class="row">

            <div class="other-details-item">
                <h1>Servicios de Medicina Ocupacional</h1>
                <p> Nuestro centro médico ofrece un completo servicio de Medicina Ocupacional, diseñado para garantizar
                    el bienestar de los trabajadores y el cumplimiento de las normativas laborales vigentes. Los servicios
                    abarcan desde la evaluación inicial del estado de salud de los empleados hasta el seguimiento de su
                    bienestar a lo largo de su vida laboral.
                </p>
                <span></span>
                <h2>Evaluaciones que Realizamos:</h2>
                <h2>Examen Preocupacional</h2>
                <ul>
                    <li>Evaluación de salud general antes de que el empleado ingrese a la empresa, asegurando
                        que esté apto para realizar las tareas asignadas.</li>
                </ul>
                <h2>Examen Ocupacional (Periódico)</h2>
                <ul>
                    <li>Evaluación periódica de los trabajadores en función de su exposición a riesgos
                        laborales específicos, con el fin de detectar posibles efectos en la salud.</li>
                </ul>

                <h2>Examen Postocupacional:</h2>
                <ul>
                    <li>Evaluación médica al finalizar la relación laboral para detectar si el empleado
                        ha sufrido alguna alteración de salud derivada del trabajo.</li>
                </ul>
                <h2>Otros Servicios:</h2>
                <ul>
                    <li>Capacitación en Salud Ocupacional: Charlas y talleres para empleados sobre prevención de riesgos laborales.</li>
                    <li>Evaluaciones de Riesgos Psicosociales: Detección y manejo de factores que afectan la salud mental y el bienestar de los trabajadores.</li>
                    <li>Consultoría en Normativas Laborales de Salud: Asesoramiento a las empresas para el cumplimiento de las normativas de salud ocupacional.</li>
                </ul>
                <h2>Ventajas del Servicio:</h2>
                <ul>
                    <li>Contribución al bienestar y productividad de los empleados.</li>
                    <li>Prevención de enfermedades ocupacionales y accidentes laborales.</li>
                    <li>Cumplimiento con las normativas legales de salud y seguridad en el trabajo.</li>
                </ul>
            </div>

    </div>
</div>
