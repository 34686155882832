<!--div class="page-title-area page-title-three">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item-two">
                <h2>Dr. Sarah Taylor</h2>
                <h3>Neurosurgeon.</h3>
                <p>MBBS in Neurology, PHD in Neurosurgeon.</p>
            </div>
        </div>
    </div>
</div-->


<section id="pediatria" class="doctor-details-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="doctor-details-item doctor-details-left">
                    <img src="assets/img/doctor/dr_01.jpg" alt="Doctor">

                    <div class="doctor-details-work">
                        <h3>Horarios</h3>
                        <div class="details-item-two-right">
                            <div class="details-item-content">
                                <div class="content-one">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p>Lunes</p>
                                            </td>
                                            <td>
                                                <p>7:00 - 20:00</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Martes</p>
                                            </td>
                                            <td>
                                                <p>7:00 - 20:00</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Miercoles </p>
                                            </td>
                                            <td>
                                                <p>7:00 - 20:00</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Jueves</p>
                                            </td>
                                            <td>
                                                <p>7:00 - 20:00</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Viernes</p>
                                            </td>
                                            <td>
                                                <p>7:00 - 20:00</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Sabado</p>
                                            </td>
                                            <td>
                                                <p>7:00 - 20:00</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </div>
                                <div class="content-three">
                                    <ul>
                                        <li><i class="icofont-ui-call"></i> 593 0993112438</li>
                                        <li><i class="icofont-ui-message"></i> info&#64;cemas.ec</li>
                                    </ul>
                                    <span> </span>
                                    <p> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="doctor-details-item">
                    <div class="doctor-details-right">
                        <div class="doctor-details-biography">
                            <h2>Dra. Violeta Garcia</h2>
                            <h3>Pediatría</h3>
                            <p>Médico en Pediatría con más de 30 años de experiencia en su rama profesional,
                                especializado en la Universidad de Zulia.
                                Dedicada al cuidado de la salud de los niños, desde que nacen hasta la adolescencia, con
                                una
                                atención de calidez, amor y responsabilidad hacia sus pacientes.
                            </p><br>
                            <h3>Atención en:</h3>
                            <ul>
                                <li> Control del niño sano.</li>
                                <li> Recepción y atención al recién nacido.</li>
                                <li> Enfermedades de lactantes, pres-escolar, escolar y adolescente.</li>
                                <li> Valoración del desarrollo y estado nutricional del niño.</li>
                                <li> Crecimiento y desarrollo de los niños.</li>
                                <li> Prevención de enfermedades.</li>
                                <li> Emergencias Pediátricas.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="doctor-details-btn">
                        <a routerLink="/appointment">Agenda tu Cita</a>
                        <!--a class="cmn-btn-right" routerLink="/about">Learn More</a-->
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>


<section id="medicinaInterna" class="doctor-details-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="doctor-details-item doctor-details-left">
                    <img src="assets/img/doctor/dr_02.jpg" alt="Doctor">

                    <div class="doctor-details-work">
                        <h3>Horarios</h3>
                        <div class="details-item-two-right">
                            <div class="details-item-content">
                                <div class="content-one">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p>Lunes</p>
                                            </td>
                                            <td>
                                                <p>7:00 - 20:00</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Martes</p>
                                            </td>
                                            <td>
                                                <p>7:00 - 20:00</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Miercoles </p>
                                            </td>
                                            <td>
                                                <p>7:00 - 20:00</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Jueves</p>
                                            </td>
                                            <td>
                                                <p>7:00 - 20:00</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Viernes</p>
                                            </td>
                                            <td>
                                                <p>7:00 - 20:00</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Sabado</p>
                                            </td>
                                            <td>
                                                <p>7:00 - 20:00</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </div>
                                <div class="content-three">
                                    <ul>
                                        <li><i class="icofont-ui-call"></i> 593 0993112438</li>
                                        <li><i class="icofont-ui-message"></i> info&#64;cemas.ec</li>
                                    </ul>
                                    <span> </span>
                                    <p> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="doctor-details-item">
                    <div class="doctor-details-right">
                        <div class="doctor-details-biography">
                            <h2>Dr. Jorge González</h2>
                            <h3>Medicina Interna</h3>
                            <p>Médico especialista en medicina interna, diagnóstico y trata todas las enfermedades que
                                pueden afectar al adulto,
                                siempre y cuando no necesiten ser tratadas quirúrgicamente.
                            </p><br>
                            <h3>Atención en:</h3>
                            <ul>
                                <li> Control de enfermedades respiratorias.</li>
                                <li> Control de enfermedades cardiovasculares.</li>
                                <li> Endocrinas.</li>
                                <li> Digestivas.</li>
                                <li> Neurológicas y renales.</li>
                                <li> Asesoría anticonceptiva.</li>
                                <li> Electrocardiograma.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="doctor-details-btn">
                        <a routerLink="/appointment">Agenda tu Cita</a>
                        <!--a class="cmn-btn-right" routerLink="/about">Learn More</a-->
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>


<section id="cardiologia" class="doctor-details-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="doctor-details-item doctor-details-left">
                    <img src="assets/img/doctor/dr_03.jpg" alt="Doctor">

                    <div class="doctor-details-work">
                        <h3>Horarios</h3>
                        <div class="details-item-two-right">
                            <div class="details-item-content">
                                <div class="content-one">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p>Lunes</p>
                                            </td>
                                            <td>
                                                <p>7:00 - 20:00</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Martes</p>
                                            </td>
                                            <td>
                                                <p>7:00 - 20:00</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Miercoles </p>
                                            </td>
                                            <td>
                                                <p>7:00 - 20:00</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Jueves</p>
                                            </td>
                                            <td>
                                                <p>7:00 - 20:00</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Viernes</p>
                                            </td>
                                            <td>
                                                <p>7:00 - 20:00</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>Sabado</p>
                                            </td>
                                            <td>
                                                <p>7:00 - 20:00</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </div>

                                <div class="content-three">
                                    <ul>
                                        <li><i class="icofont-ui-call"></i> 593 0993112438</li>
                                        <li><i class="icofont-ui-message"></i> info&#64;cemas.ec</li>
                                    </ul>
                                    <span> </span>
                                    <p> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="doctor-details-item">
                    <div class="doctor-details-right">
                        <div class="doctor-details-biography">
                            <h2>Dr. Joao Torres</h2>
                            <h3>Cardiología</h3>
                            <p>Médico en Cardiología especializado en la Universidad FavaloroArgentina. Estudia,
                                diagnóstica y trata,
                                las enfermedades que afectan directamente al corazón y al aparato circulatorio de los
                                pacientes.
                            </p><br>
                            <h3>Atención en:</h3>
                            <ul>
                                <li> Prueba de Esfuerzo, Ergometría.</li>
                                <li> Monitoreo ambulatorio de presión arterial M.A.P.A.</li>
                                <li> Riesgo Prequirúrgicos.</li>
                                <li> Holter de 24 y 48 horas.</li>
                                <li> Electrocardiograma.</li>
                                <li> Ecocardiograma Doppler.</li>
                                <li> Ablación.</li>
                                <li> Cirugías Cardiovasculares.</li>
                                <li> Valoraciones Pre-Quirúrgicas.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="doctor-details-btn">
                        <a routerLink="/appointment">Agenda tu Cita</a>
                        <!--a class="cmn-btn-right" routerLink="/about">Learn More</a-->
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>


<section id="audiometria" class="doctor-details-container">
    <article class="doctor-details-view1">
        <img src="assets/img/doctor/dr_02.jpg" alt="Doctor">
        <div class="doctor-details-btn">
            <a routerLink="/appointment">Agenda tu Cita</a>
            <!--a class="cmn-btn-right" routerLink="/about">Learn More</a-->
        </div>
    </article>

    <article class="doctor-details-view2">
        <h1>Dr. Jorge González</h1>
        <h2>Medicina Interna</h2>
        <p>Médico especialista en medicina interna, diagnóstico y trata todas las enfermedades que pueden afectar al
            adulto,
            siempre y cuando no necesiten ser tratadas quirúrgicamente.
        </p><br>
        <h2>Atención en:</h2>
        <ul>
            <li> Control de enfermedades respiratorias.</li>
            <li> Control de enfermedades cardiovasculares.</li>
            <li> Endocrinas.</li>
            <li> Digestivas.</li>
            <li> Neurológicas y renales.</li>
            <li> Asesoría anticonceptiva.</li>
            <li> Electrocardiograma.</li>
        </ul>
    </article>

    <article class="doctor-details-view3">
        <h1>Horarios</h1>
        <tbody>
            <tr>
                <td>
                    <p>Lunes</p>
                </td>
                <td>
                    <p>7:00 - 20:00</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Martes</p>
                </td>
                <td>
                    <p>7:00 - 20:00</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Miercoles </p>
                </td>
                <td>
                    <p>7:00 - 20:00</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Jueves</p>
                </td>
                <td>
                    <p>7:00 - 20:00</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Viernes</p>
                </td>
                <td>
                    <p>7:00 - 20:00</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Sabado</p>
                </td>
                <td>
                    <p>7:00 - 20:00</p>
                </td>
            </tr>



        </tbody>



        <div class="infodate">
            <p><i class="icofont-ui-call"></i> 593 0993112438</p>
            <p><i class="icofont-ui-message"></i> info&#64;cemas.ec</p>
        </div>


    </article>
</section>