import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  /*imgCollection: Array<object> = [
    {
      image: 'assets/img/doctor/dr_01.jpg',
      thumbImage: 'assets/img/doctor/dr_01.jpg',
      alt: 'Image 1',
      title: 'Image 1'
    }, {
      image: 'assets/img/doctor/dr_02.jpg',
      thumbImage: 'assets/img/doctor/dr_02.jpg',
      title: 'Image 2',
      alt: 'Image 2'
    }, {
      image: 'assets/img/doctor/dr_03.jpg',
      thumbImage: 'assets/img/doctor/dr_03.jpg',
      title: 'Image 3',
      alt: 'Image 3'
    }, {
      image: 'assets/img/doctor/dr_04.jpg',
      thumbImage: 'assets/img/doctor/dr_04.jpg',
      title: 'Image 4',
      alt: 'Image 4'
    }, {
      image: 'assets/img/doctor/dr_05.jpg',
      thumbImage: 'assets/img/doctor/dr_05.jpg',
      title: 'Image 5',
      alt: 'Image 5'
    }, {
      image: 'assets/img/doctor/dr_06.jpg',
      thumbImage: 'assets/img/doctor/dr_06.jpg',
      title: 'Image 6',
      alt: 'Image 6'
    }, {
      image: 'assets/img/doctor/dr_07.jpg',
      thumbImage: 'assets/img/doctor/dr_07.jpg',
      title: 'Image 7',
      alt: 'Image 7'
    }, {
      image: 'assets/img/doctor/dr_08.jpg',
      thumbImage: 'assets/img/doctor/dr_08.jpg',
      title: 'Image 8',
      alt: 'Image 8'
    }, {
      image: 'assets/img/doctor/dr_09.jpg',
      thumbImage: 'assets/img/doctor/dr_09.jpg',
      title: 'Image 9',
      alt: 'Image 9'
    }, {
      image: 'assets/img/doctor/dr_10.jpg',
      thumbImage: 'assets/img/doctor/dr_10.jpg',
      title: 'Image 6',
      alt: 'Image 6'
    }, {
      image: 'assets/img/doctor/dr_11.jpg',
      thumbImage: 'assets/img/doctor/dr_11.jpg',
      title: 'Image 6',
      alt: 'Image 6'
    }
  ]*/

  /*servicesSlides: OwlOptions = {
		margin: 25,
		nav: false,
		loop: true,
		dots: true,
		autoplay: false,
		autoplayHoverPause: true,
		navText: [
			"<i class='ph-caret-left'></i>",
			"<i class='ph-caret-right'></i>",
		],
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 1
			},
			768: {
				items: 2
			},
			992: {
				items: 3
			},
			1200: {
				items: 3
			}
		}
    }*/

    customOptions: OwlOptions = {
      nav: true,
      margin: 10,
      //autoplay: para que se mueva automaticamente el carrusel
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      dots: false,
      navSpeed: 700,
      navText: [
        "<i class='icofont-arrow-right icofont-rotate-180'></i>",
        "<i class='icofont-arrow-right icofont-normal'></i>",
      ],


      responsive: {
        0: {
          items: 1
        },
        300: {
          items: 2
        },
        500: {
          items: 3
        },
        700: {
          items: 4
        },
        900: {
          items: 5
        }

      }
    }



}
