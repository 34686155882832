<div class="page-title-area page-title-department">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Especialidades</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Especialidades</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="departments-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="department-item">
                    <div class="department-front">
                        <a routerLink="/doctor-details" fragment="pediatria">

                            <img class="icon-medium" alt="" decoding="async" src="assets\img\iconos\departments\SVG\001.svg" />
                        </a>
                        <ul></ul>
                        <ul></ul>
                        <h3>Pediatría</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="department-item">
                    <div class="department-front">
                        <a routerLink="/doctor-details" fragment="audiometria">
                            <img class="icon-medium" alt="" decoding="async" src="assets\img\iconos\departments\SVG\002.svg" />
                        </a>
                        <ul></ul>
                        <ul></ul>
                        <h3>Audiometria</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="department-item">
                    <div class="department-front">
                        <a routerLink="/doctor-details" fragment="cardiologia">
                            <img class="icon-medium" alt="" decoding="async" src="assets\img\iconos\departments\SVG\003.svg" />
                        </a>
                        <ul></ul>
                        <ul></ul>
                        <h3>Cardiología</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="department-item">
                    <div class="department-front">
                        <a routerLink="/doctor-details">
                            <img class="icon-medium" alt="" decoding="async" src="assets\img\iconos\departments\SVG\004.svg" />
                        </a>
                        <ul></ul>
                        <ul></ul>
                        <h3>Dermatologia</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="department-item">
                    <div class="department-front">
                        <a routerLink="/doctor-details">
                            <img class="icon-medium" alt="" decoding="async" src="assets\img\iconos\departments\SVG\005.svg" />
                        </a>
                        <ul></ul>
                        <ul></ul>
                        <h3>Endocrinologia</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="department-item">
                    <div class="department-front">
                        <a routerLink="/doctor-details">
                            <img class="icon-medium" alt="" decoding="async" src="assets\img\iconos\departments\SVG\006.svg" />
                        </a>
                        <ul></ul>
                        <ul></ul>
                        <h3>Nutrición Clinica</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="department-item">
                    <div class="department-front">
                        <a routerLink="/doctor-details">
                            <img class="icon-medium" alt="" decoding="async" src="assets\img\iconos\departments\SVG\007.svg" />
                        </a>
                        <ul></ul>
                        <ul></ul>
                        <h3>Cirugía</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="department-item">
                    <div class="department-front">
                        <a routerLink="/doctor-details"  fragment="medicinaInterna">
                            <img class="icon-medium" alt="" decoding="async" src="assets\img\iconos\departments\SVG\008.svg" />
                        </a>
                        <ul></ul>
                        <ul></ul>
                        <h3>Medicina Interna</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="department-item">
                    <div class="department-front">
                        <a routerLink="/doctor-details">
                            <img class="icon-medium" alt="" decoding="async" src="assets\img\iconos\departments\SVG\009.svg" />
                        </a>
                        <ul></ul>
                        <ul></ul>
                        <h3>Medicina General</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="department-item">
                    <div class="department-front">
                        <a routerLink="/doctor-details">
                            <img class="icon-medium" alt="" decoding="async" src="assets\img\iconos\departments\SVG\010.svg" />
                        </a>
                        <ul></ul>
                        <ul></ul>
                        <h3>Ginecología</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="department-item">
                    <div class="department-front">
                        <a routerLink="/doctor-details">
                            <img class="icon-medium" alt="" decoding="async" src="assets\img\iconos\departments\SVG\011.svg" />
                        </a>
                        <ul></ul>
                        <ul></ul>
                        <h3>Obstetricia</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="department-item">
                    <div class="department-front">
                        <a routerLink="/doctor-details">
                            <img class="icon-medium" alt="" decoding="async" src="assets\img\iconos\departments\SVG\012.svg" />
                        </a>
                        <ul></ul>
                        <ul></ul>
                        <h3>Oftamología</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="department-item">
                    <div class="department-front">
                        <a routerLink="/doctor-details">
                            <img class="icon-medium" alt="" decoding="async" src="assets\img\iconos\departments\SVG\013.svg" />
                        </a>
                        <ul></ul>
                        <ul></ul>
                        <h3>Gastroenteorología</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="department-item">
                    <div class="department-front">
                        <a routerLink="/doctor-details">
                            <img class="icon-medium" alt="" decoding="async" src="assets\img\iconos\departments\SVG\014.svg" />
                        </a>
                        <ul></ul>
                        <ul></ul>
                        <h3>Hematología</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="department-item">
                    <div class="department-front">
                        <a routerLink="/doctor-details">
                            <img class="icon-medium" alt="" decoding="async" src="assets\img\iconos\departments\SVG\015.svg" />
                        </a>
                        <ul></ul>
                        <ul></ul>
                        <h3>Alergología</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="department-item">
                    <div class="department-front">
                        <a routerLink="/doctor-details">
                            <img class="icon-medium" alt="" decoding="async" src="assets\img\iconos\departments\SVG\016.svg" />
                        </a>
                        <ul></ul>
                        <ul></ul>
                        <h3>Otorrinolaringologia</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="department-item">
                    <div class="department-front">
                        <a routerLink="/doctor-details">
                            <img class="icon-medium" alt="" decoding="async" src="assets\img\iconos\departments\SVG\017.svg" />
                        </a>
                        <ul></ul>
                        <ul></ul>
                        <h3>Neurología</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="department-item">
                    <div class="department-front">
                        <a routerLink="/doctor-details">
                            <img class="icon-medium" alt="" decoding="async" src="assets\img\iconos\departments\SVG\018.svg" />
                        </a>
                        <ul></ul>
                        <ul></ul>
                        <h3>Psicología</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="department-item">
                    <div class="department-front">
                        <a routerLink="/doctor-details">
                            <img class="icon-medium" alt="" decoding="async" src="assets\img\iconos\departments\SVG\019.svg" />
                        </a>
                        <ul></ul>
                        <ul></ul>
                        <h3>Traumatología</h3>
                        <ul></ul>
                        <ul></ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="department-item">
                    <div class="department-front">
                        <a routerLink="/doctor-details">
                            <img class="icon-medium" alt="" decoding="async" src="assets\img\iconos\departments\SVG\020.svg" />
                        </a>
                        <ul></ul>
                        <ul></ul>
                        <h3>Urología</h3>
                        <a></a>
                        <ul></ul>
                        <ul></ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
